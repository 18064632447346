import React from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NavBar2 from '../../components/navigation/NavBar2';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paperAppBar:{
    marginTop:"-1%",
    marginBottom:"1%"
  },
  toolbar: theme.mixins.toolbar,
  button:{
    marginTop: theme.spacing.unit * 3
  },
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    width: "100%",
    marginLeft: "4%",
    marginRight: "4%",
    marginTop: theme.spacing.unit * 3,
  }),
  bigAvatar: {
  width: 100,
  height: 100,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  textFieldHalf:{
    marginLeft: theme.spacing.unit * .5,
    marginRight: theme.spacing.unit * .5,
    width: "48%"
  }
});


class Profile extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      first_name: "",
      last_name:"",
      email:"",
      phone:"",
      newPassword:"",
      snackOpen: false,
      snackVariant:"success",
      snackMessage:"Password has been updated"
    };
  }

   changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`
    this.props.history.push(path);
    };

    handleChange = name => event => {
      this.setState({
        [name]: event.target.value,
      });
    };

    handleSnackClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      this.setState({ snackOpen: false });
    };

updateUser = () => {
  var self = this;
  self.setState({
  updateUserFinished: false
  });
  axios({
   method: 'PUT',
   url: `${apiBaseUrl}/v1/org/profile`,
   headers: {
     'Authorization': 'Bearer ' + localStorage.getItem('token'),
     'Content-Type' : 'application/json'
   },
   data:{
     'first_name': this.state.first_name,
     'last_name': this.state.last_name,
     'email': this.state.email,
     'phone': this.state.phone
   }
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      self.setState({
        snackOpen: true,
        snackVariant:"success",
        snackMessage:"Your profile has been updated"
      });
      self.fetchUser()
    }})
  .catch(function (error) {
    self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: error.response.data.errorsArray[0].msg
      })
  });
}

updatePassword = () => {
  var self = this;
  self.setState({
  updateUserFinished: false
  });
  axios({
   method: 'PUT',
   url: `${apiBaseUrl}/v1/org/password`,
   headers: {
     'Authorization': 'Bearer ' + localStorage.getItem('token'),
     'Content-Type' : 'application/json'
   },
   data:{
     'password': this.state.newPassword,
   }
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      self.setState({
      updatePasswordFinished: true,
      newPassword: "",
      snackOpen: true,
      snackVariant:"success",
      snackMessage:"Password has been updated"
      });
      //self.fetchUser()
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({
    snackOpen: true,
    snackVariant:"error",
    snackMessage:"Password too short"
    });
  });
}

fetchUser = () => {
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    if(response.status === 200){
      const admin = response.data.admins.filter((admin)=>admin._id === response.data.request_made_by)[0]
      console.log(admin)
      self.setState({
      user: response.data,
      first_name: admin.first_name,
      last_name: admin.last_name,
      email: admin.email,
      username: admin.username,
      phone: admin.phone,
      finished: true
      });
    }else{
      let path = `/${self.state.org_handle}/login`
      self.props.history.push(path);
    }
  }).catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving user profile"})
  });
}

componentDidMount(event){
 this.fetchUser()
}

render() {
  console.log(this.state)
  const { classes, ...other } = this.props;

    return (

      <NavBar2 header="Your Profile" {...other}>

      <Grid container spacing={24}>
      <Paper className={classes.paper} elevation={4}>
      <AppBar position="static" className={classes.paperAppBar} color="secondary">
         <Toolbar>
           <Typography variant="title" color="inherit" className={classes.flex}>
             Profile
           </Typography>
         </Toolbar>
       </AppBar>

      <form className={classes.container} noValidate autoComplete="off">
      <Grid container justify={"center"}>
      <TextField
      label="First Name"
      id="margin-none"
      type="text"
      value={this.state.first_name}
      className={classes.textFieldHalf}
      helperText="Enter your first name"
      onChange={this.handleChange('first_name')}
      />

      <TextField
      label="Last Name"
      id="margin-none"
      type="text"
      value={this.state.last_name}
      className={classes.textFieldHalf}
      helperText="Enter your last name"
      onChange={this.handleChange('last_name')}
      />
      </Grid>
      <Grid container justify={"center"}>
      <TextField
      label="Email"
      id="margin-none"
      fullWidth
      type="email"
      value={this.state.email}
      className={classes.textFieldHalf}
      helperText="Enter your username"
      onChange={this.handleChange('email')}
      />
      <TextField
      label="Phone"
      id="margin-none"
      fullWidth
      type="tel"
      value={this.state.phone}
      className={classes.textFieldHalf}
      helperText="Enter your phone number"
      onChange={this.handleChange('phone')}
      />
      </Grid>

        <Grid container justify={"center"}>
        <br />
        <Button
        variant="raised"
        color="primary"

        className={classes.button}
        onClick={(event) => this.updateUser()}
        >
        Update Profile
        </Button>
        </Grid>

        </form>
      </Paper>

      <Paper className={classes.paper} elevation={4}>
      <AppBar position="static" className={classes.paperAppBar} color="secondary">
         <Toolbar>
           <Typography variant="title" color="inherit" className={classes.flex}>
             Password
           </Typography>
         </Toolbar>
       </AppBar>
      <form className={classes.container} noValidate autoComplete="off">
      <TextField
      label="New Password"
      id="margin-none"
      fullWidth
      type="password"
      value={this.state.newPassword}
      className={classes.textField}
      helperText="Enter your new password"
      onChange={this.handleChange('newPassword')}
      />

        <Grid container justify={"center"}>
        <br />
        <Button
        variant="raised"
        color="primary"

        className={classes.button}
        onClick={(event) => this.updatePassword()}
        >
        Change Password
        </Button>
        </Grid>

        </form>
      </Paper>
      </Grid>
        <Snackbar
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'center',
           }}
           open={this.state.snackOpen}
           autoHideDuration={3000}
           onClose={this.handleSnackClose}
           >
             <MySnackbarContentWrapper
             variant={this.state.snackVariant}
             message={this.state.snackMessage}
             onClose={this.handleSnackClose}
            />
        </Snackbar>

      </NavBar2>
    );
  }
}


//
/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Profile);
