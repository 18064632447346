import React from 'react';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NavBar2 from '../../components/navigation/NavBar2';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paginationButton:{
    margin: theme.spacing.unit
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    //padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingTop: 50,
    paddingBottom: 50,
    //marginTop: theme.spacing.unit * 3,
  }),
  grow: {
     flexGrow: 1,
   },
   menuButton: {
     marginLeft: -12,
     marginRight: 20,
   },
   title: {
     display: 'none',
     [theme.breakpoints.up('sm')]: {
       display: 'block',
     },
   },
   search: {
     position: 'relative',
     borderRadius: theme.shape.borderRadius,
     backgroundColor: fade(theme.palette.common.white, 0.15),
     '&:hover': {
       backgroundColor: fade(theme.palette.common.white, 0.25),
     },
     marginLeft: 0,
     width: '100%',
     [theme.breakpoints.up('sm')]: {
       marginLeft: theme.spacing.unit,
       width: 'auto',
     },
   },
   searchIcon: {
     width: theme.spacing.unit * 9,
     height: '100%',
     position: 'absolute',
     pointerEvents: 'none',
     display: 'flex',
     alignItems: 'center',
     justifyContent: 'center',
   },
   inputRoot: {
     color: 'inherit',
     width: '100%',
   },
   inputInput: {
     paddingTop: theme.spacing.unit,
     paddingRight: theme.spacing.unit,
     paddingBottom: theme.spacing.unit,
     paddingLeft: theme.spacing.unit * 10,
     transition: theme.transitions.create('width'),
     width: '100%',
     [theme.breakpoints.up('sm')]: {
       width: 120,
       '&:focus': {
         width: 200,
       },
     },
   },
   fab: {
  position: 'absolute',
  bottom: theme.spacing.unit * 11,
  right: theme.spacing.unit * 3,
  zIndex: 9999,
},
});



class Forms extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 10,
      forms:[],
      searchValue: ""
    };
  }

   changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`
    this.props.history.push(path);
 };

 handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
  };

  nextPage = (event) => {
    this.loadForms(Number(this.state.page)+1, Number(this.state.rowsPerPage))
    this.setState({
    page: this.state.page+1
    });
  }

  previousPage = (event) => {
    this.loadForms(Number(this.state.page)-1, Number(this.state.rowsPerPage))
    this.setState({
    page: this.state.page-1
    });
  }


componentDidMount(event){
  console.log(this.state)
  this.loadForms(Number(this.state.page), Number(this.state.rowsPerPage))

  window.intercomSettings = {
      app_id: "nhry3flh",
      user_hash: localStorage.getItem('intercom_hash')
      //created_at: <%= current_user.created_at.to_i %> // Signup Date
    };
}

loadForms = (page, per_page)=>{
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/org/forms?page=${page}&per_page=${per_page}`,
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      self.setState({
      forms: response.data,
      finished: true
      });
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving forms"})
  });
}

searchForms = ()=>{
  var self = this;
  if (!this.state.searchValue) {
    this.loadForms(Number(this.state.page), Number(this.state.rowsPerPage))
  }else{
  axios({
   method: 'POST',
   url: `${apiBaseUrl}/v1/org/forms/search`,
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
   data: {
     searchValue: this.state.searchValue
   }
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      if (response.data.length > 0) {
      self.setState({
      forms: response.data,
      finished: true
      });
    }
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving forms"})
  });
}
}

render() {
  const { classes, ...other } = this.props;
  const forms = []
  console.log(this.state)
  for (let form of this.state.forms) {
    let formUrl = `https://bt-forms.instapayments.io/forms/signup/${form._id}`
    if (form.kind == "onetime") {
      formUrl = `https://bt-forms.instapayments.io/forms/onetime/${form._id}`
    }
    forms.push(
      <TableRow key={form._id}>
        <TableCell >{form.name}</TableCell>
        <TableCell >{form.redirect_url}</TableCell>
        <TableCell >{form.kind}</TableCell>
        <TableCell >{moment(new Date(form.createdAt)).format('MM/DD/YYYY')}</TableCell>
        <TableCell >
        <Button size="small" variant="outlined" color="primary" target="_blank" href={formUrl} >
        View
        </Button>
        <Button size="small" variant="outlined" color="primary" href={`/forms/update/${form._id}`} >
        Edit
        </Button>
        </TableCell>

      </TableRow>
    )}

    return (
      <div className={classes.root}>
        <NavBar2>

          {this.state.forms.length > 0 ?
            <div>
            <Paper>
            <AppBar position="static">
            <Toolbar>

            <Typography variant="h6" color="inherit" className={classes.grow}>
            Forms
            </Typography>
            <Button variant="outlined" color="inherit" href="/forms/new">Create a Form</Button>
            </Toolbar>
            </AppBar>
            </Paper>
          <Paper>

          <Table className={classes.table}>

            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Redirect URL</TableCell>
                <TableCell >Form Type</TableCell>
                <TableCell >Created At</TableCell>
                <TableCell >Form Url</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>

            {forms}

            </TableBody>
            <TableFooter>
         <TableRow>
         <TableCell>
           Page {this.state.page}
         </TableCell>
         <TableCell/>
        <TableCell/>
        <TableCell/>
           <TableCell>
             <Button
             size="small"
             variant="raised"
             color="primary"
             onClick={(event) => this.previousPage(event)}
             className={classes.paginationButton}
             disabled={this.state.page <= 1}>
             Previous
             </Button>
             <Button
             size="small"
             variant="raised"
             color="primary"
             onClick={(event) => this.nextPage(event)}
             className={classes.paginationButton}
             disabled={this.state.forms.length < 10}>
             Next
             </Button>
           </TableCell>
         </TableRow>
       </TableFooter>
          </Table>
        </Paper>
           </div>
           :
                <div>
                <Grid container justify={"center"}>
                <Card className={classes.card}>
           <CardActionArea>
             <CardMedia
               className={classes.media}
               image="/static/images/cards/contemplative-reptile.jpg"
               title="Contemplative Reptile"
             />
             <CardContent>
               <Typography gutterBottom variant="headline" component="h2">
                 You don't have a customer yet!
               </Typography>
               <Typography component="p">
                 Forms are pulled from Braintree. You can easily signup forms by creating a form in the forms tab, or creating your own signup process with Stripes API.
               </Typography>
             </CardContent>
           </CardActionArea>
           <CardActions>
             <Button
             variant="raised"
             color="primary"
             href="/forms/new"
             >
               Create a Form
             </Button>
           </CardActions>
         </Card>
         </Grid>
         </div>

                }

        </NavBar2>
      </div>
    );
  }
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Forms);
