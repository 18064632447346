import React from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import InputLabel from '@material-ui/core/InputLabel';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import NavBar2 from '../../components/navigation/NavBar2';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import getSymbolFromCurrency from 'currency-symbol-map';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Snackbar from '@material-ui/core/Snackbar';
import NativeSelect from '@material-ui/core/NativeSelect';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    width: "80%",
    marginLeft: "8%",
    marginRight: "8%",
    marginTop: theme.spacing.unit * 3,
  }),
  button:{
    marginTop: theme.spacing.unit * 2
  },
  textField:{
    marginTop: theme.spacing.unit
  },
  formControl: {
    marginTop: theme.spacing.unit,
    minWidth: 120,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};


class NewForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    name: "",
    description:"",
    amount:0,
    kind: "subscription",
    redirect_url:"",
    currency: "USD",
    instructionType: "oneTime",
    allowPassThrough: false,
    products: [],
    selectedPlans: []
    };
  }

  changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`
    this.props.history.push(path);
  };

 handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
};

handleCheckboxChange = name => event => {
  this.setState({
    [name]: !this.state[name],
  });
};

handleSnackClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  this.setState({ snackOpen: false });
};

fetchProducts = () => {
    var self = this;
    console.log(this.state)
    axios({
     method: 'get',
     url: `${apiBaseUrl}/v1/org/products`,
     headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
   })
    .then(function (response) {
      console.log(response.data);
      if(response.status === 200){
        self.setState({
        products: response.data
        });
        console.log(self.state)
      }})
    .catch(function (error) {
      console.log(error);
      self.setState({messageError:"Error retrieving products"})
    });
}


createForm = (event) =>{
  event.preventDefault();
  var self = this;
  axios({
   method: 'POST',
   url: `${apiBaseUrl}/v1/org/forms`,
   headers: {
     'Authorization': 'Bearer ' + localStorage.getItem('token'),
     'Content-Type' : 'application/json'
   },
   data:{
     'name': this.state.name,
     'description': this.state.description,
     'plan_id': this.state.plan_id,
     'kind': this.state.kind,
     'amount': this.state.amount,
     'redirect_url': this.state.redirect_url,
     'allow_frontend_passthrough': this.state.allowPassThrough,
     'currency':this.state.currency
   }
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      self.setState({
        snackOpen: true,
        snackVariant:"success",
        snackMessage:"Your one time charge Braintree form URL has been created."
      });
      let path = `/forms`
      self.props.history.push(path);
    }})
  .catch(function (error) {
    console.log(error)
    self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: "error creating url"
      })
  });
}


componentDidMount(event){
  console.log(this.state)
  this.fetchProducts()
  window.intercomSettings = {
      app_id: "nhry3flh",
      user_hash: localStorage.getItem('intercom_hash')
      //created_at: <%= current_user.created_at.to_i %> // Signup Date
    };
}

render() {
  const { classes, theme, ...other } = this.props;
  console.log(this.state)


  const productArray =[];

      for (let product of this.state.products) {
        let plans =[]
        productArray.push(

          <option value={product.id}>{product.name}</option>
        )

      }


    return (
      <NavBar2 header="New Form" {...other}>


          <Paper className={classes.paper}>



          <form onSubmit={this.createForm}>
          <TextField
          label="Name"
          fullWidth
          required
          type="text"
          helperText="Name so you can easily identify the checkout."
          value={this.state.name}
          className={classes.textField}
          onChange={this.handleChange('name')}
          />
          <FormControl>
          <Select
          native
          required
          value={this.state.kind}
          onChange={this.handleChange('kind')}
          inputProps={{
            name: 'Type',
            id: 'type',
            required: true
          }}
          >
          <option selected value="subscription">Subscription</option>
          <option value="onetime">One Time</option>
          </Select>
          <FormHelperText>The type of form you want.</FormHelperText>
          </FormControl>

          <TextField
          label="Description"
          fullWidth
          required
          type="text"
          helperText="Internal description."
          value={this.state.description}
          className={classes.textField}
          onChange={this.handleChange('description')}
          />
          { this.state.kind == "subscription" ?
          <FormControl>
          <Select
          native
          required
          onChange={this.handleChange('plan_id')}
          inputProps={{
            name: 'plan_id',
            id: 'plan_id',
            required: true
          }}
          >
          <option hidden disabled selected value>Pick a product</option>
          {productArray}
          </Select>
          <FormHelperText>The plan you want the customer subscribed to.</FormHelperText>
          </FormControl>
          :
          null }
          { this.state.kind == "onetime" ?
          <TextField
          label="Amount to Charge"
          fullWidth
          required
          value={this.state.amount}
          helperText="Amount you want Stripe to charge the customer when they complete the form."
          type="number"
          InputProps={{
            inputProps: {
              step: "any",
              min:0
            },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          className={classes.textField}
          onChange={this.handleChange('amount')}
          />
          :null}

          <TextField
          label="Redirect Url"
          fullWidth
          type="text"
          helperText="The URL you want us to send the customer to when the checkout is complete."
          value={this.state.redirect_url}
          className={classes.textField}
          onChange={this.handleChange('redirect_url')}
          />

          <Button
          variant="raised"
          color="primary"
          fullWidth={true}
          className={classes.button}
          type="submit"
          >
          Create Form
          </Button>
          </form>

        </Paper>
        <Snackbar
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'center',
           }}
           open={this.state.snackOpen}
           autoHideDuration={3000}
           onClose={this.handleSnackClose}
           >
             <MySnackbarContentWrapper
             variant={this.state.snackVariant}
             message={this.state.snackMessage}
             onClose={this.handleSnackClose}
            />
        </Snackbar>
      </NavBar2>
    );
  }
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(NewForm);
