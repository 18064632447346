import React from 'react';

export default class Logout extends React.Component {

  constructor(props) {
    super(props);

  }
  componentDidMount() {
  localStorage.removeItem('token');
  localStorage.removeItem('timestamp');
  let path = "/login"
  if (this.props.match.params.org) {
    path= `/${this.props.match.params.org}/login`
  }

  this.props.history.push(path);
  }
  render() {
    return (
      <div>

      </div>
    );
  }
}
