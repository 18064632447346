// MyStoreCheckout.js
import React from 'react';
import {Elements} from 'react-stripe-elements';
import InjectedCheckoutForm from './CardUpdateForm';

class CardUpdate extends React.Component {
  render() {
    return (
      <Elements>
        <InjectedCheckoutForm {...this.props}/>
      </Elements>

    );
  }
}

export default CardUpdate;
