// CheckoutForm.js
import React from 'react';
import axios from 'axios';
import {injectStripe} from 'react-stripe-elements';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
//import AddressSection from './AddressSection';
import CardSection from './CardSection';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = theme => ({

 textField: {
   marginBottom: theme.spacing.unit * 3,
 }
});

class CheckoutForm extends React.Component {

  constructor(props){
    super(props);
    this.state={
      card_name:'',
      terms: false,
      billingFinished: true
    }
  }

  handleChange = name => event => {
   this.setState({
     [name]: event.target.value,
   });
 };

  handleCheckboxChange = name => event => {
   this.setState({
     [name]: !this.state[name],
   });
  };

  createStripeCustomer = (token) =>{
    var self = this;
    self.setState({
    billingFinished: false
    });
    axios({
     method: 'POST',
     url: `${apiBaseUrl}/v1/org/billing/subscription?source=true`,
     headers: {
       'Authorization': 'Bearer ' + localStorage.getItem('token'),
       'Content-Type' : 'application/json'
     },
     data:{
       'token': token,
       "plan":"plan_DtWYQetIDhbUFV"
     }
   })
    .then(function (response) {
      if(response.status === 200){
        self.setState({
        billingFinished: true
        });

        let path = `/chargeinstructions/onetime`
        self.props.history.push(path);

      }})
    .catch(function (error) {
      console.log(error);
    });
  }

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    if(this.state.terms ){
      this.props.stripe.createToken({name: this.state.card_name}).then(({token}) => {
        console.log('Received Stripe token:', token);
        if (token) {
            this.createStripeCustomer(token.id)
        }else{
          alert("Make sure the credit card field isn't empty.")
        }
      });
    }


    // However, this line of code will do the same thing:
    //
    // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});

    // You can also use createSource to create Sources. See our Sources
    // documentation for more: https://stripe.com/docs/stripe-js/reference#stripe-create-source
    //
    // this.props.stripe.createSource({type: 'card', name: 'Jenny Rosen'});
  };

  render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>

      <Typography variant="body1" color="inherit">
         Start your 14 day no risk FREE trial. You can cancel anytime.
      </Typography>

      <TextField
      label="Name on Card"
      id="margin-none"
      fullWidth
      className={classes.textField}
      onChange={this.handleChange('card_name')}
      />

        <CardSection />

        <FormControlLabel
                 control={
                   <Checkbox
                     checked={this.state.terms}
                     onChange={this.handleCheckboxChange('terms')}

                     color="primary"
                     required
                   />
                 }
                 label= {<div><span style={{"color": "#000"}}>I agree to the </span><a href="https://instapayments.io/terms" rel="noopener noreferrer" target="_blank">terms of use</a></div>}
               />
        <Grid container justify={"center"}>
        <br />
        <Button
        variant="raised"
        color="primary"
        type="submit"
        disabled={!this.state.billingFinished}
        >
        Finish
        </Button>
        </Grid>
      </form>
    );
  }
}

export default injectStripe(withStyles(styles)(CheckoutForm));
