// CheckoutForm.js
import React from 'react';
import axios from 'axios';
import {injectStripe} from 'react-stripe-elements';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
//import AddressSection from './AddressSection';
import CardSection from './CardSection';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = theme => ({

 textField: {
   marginBottom: theme.spacing.unit * 3,
 }
});

class CheckoutForm extends React.Component {

  constructor(props){
    super(props);
    this.state={
      card_name:'',
      terms: false,
      billingFinished: true
    }
  }

  handleChange = name => event => {
   this.setState({
     [name]: event.target.value,
   });
 };

  handleCheckboxChange = name => event => {
   this.setState({
     [name]: !this.state[name],
   });
  };

  updateCard = (token) =>{
    var self = this;
    self.setState({
    billingFinished: false
    });
    axios({
     method: 'PUT',
     url: `${apiBaseUrl}/v1/org/billing/card`,
     headers: {
       'Authorization': 'Bearer ' + localStorage.getItem('token'),
       'Content-Type' : 'application/json'
     },
     data:{
       'token': token,
       "plan":"plan_DtWYQetIDhbUFV"
     }
   })
    .then(function (response) {
      if(response.status === 200){
        self.setState({
        billingFinished: true
        });

        self.setState({
          snackOpen: true,
          snackVariant:"success",
          snackMessage:"Card Updated Successfully"
        });

      }})
    .catch(function (error) {
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage:"There was an error updating your card"
      });
    });
  }
  handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snackOpen: false });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    if(this.state.terms ){
      this.props.stripe.createToken({name: this.state.card_name}).then(({token}) => {
        console.log('Received Stripe token:', token);
        if (token) {
            this.updateCard(token.id)
        }else{
          this.setState({
            snackOpen: true,
            snackVariant:"error",
            snackMessage:"Please make sure your card is valid."
          });
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
      <form onSubmit={this.handleSubmit}>

      <TextField
      label="Name on Card"
      id="margin-none"
      fullWidth
      className={classes.textField}
      onChange={this.handleChange('card_name')}
      />

        <CardSection />

        <FormControlLabel
                 control={
                   <Checkbox
                     checked={this.state.terms}
                     onChange={this.handleCheckboxChange('terms')}

                     color="primary"
                     required
                   />
                 }
                 label= {<div><span style={{"color": "#000"}}>I agree to the </span><a href="https://instapayments.io/terms" rel="noopener noreferrer" target="_blank">terms of use</a></div>}
               />
        <Grid container justify={"center"}>
        <br />
        <Button
        variant="raised"
        color="primary"
        type="submit"
        disabled={!this.state.billingFinished}
        >
        Update
        </Button>
        </Grid>

      </form>
      <Snackbar
         anchorOrigin={{
           vertical: 'bottom',
           horizontal: 'center',
         }}
         open={this.state.snackOpen}
         autoHideDuration={3000}
         onClose={this.handleSnackClose}
         >
           <MySnackbarContentWrapper
           variant={this.state.snackVariant}
           message={this.state.snackMessage}
           onClose={this.handleSnackClose}
          />
      </Snackbar>
      </div>
    );
  }
}

export default injectStripe(withStyles(styles)(CheckoutForm));
