import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  directions:{
    padding:"10px",
    marginBottom: "10px"
  }
});

function Directions(props) {
  const { classes, className, children, message} = props;
  return (
    <div>
    <Paper className={classes.directions}>
    <Typography variant="body1">
    {children}
    {message}
    </Typography>
    </Paper>
    </div>
  );
}


export default withStyles(styles)(Directions);
