import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NavBar2 from '../../components/navigation/NavBar2';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import {StripeProvider} from 'react-stripe-elements';
import CardUpdate from '../../components/checkout/CardUpdate';
import Directions from '../../components/directions/Directions';
import axios from 'axios';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const drawerWidth = 200;

const styles = theme => ({

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paperAppBar:{
    marginTop:"-1%",
    marginBottom:"1%"
  },
  toolbar: theme.mixins.toolbar,
  button:{
    marginTop: theme.spacing.unit * 3
  },
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    width: "100%",
    marginLeft: "4%",
    marginRight: "4%",
    marginTop: theme.spacing.unit * 3,
  }),
  popover: {
     position: 'absolute',
     zIndex: '2',
     marginBottom: "2%"
   },
   brandingButton:{
     display: "inline",
     width: "33%",
     marginTop: theme.spacing.unit * 3
   },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  textFieldHalf:{
    marginLeft: theme.spacing.unit * .5,
    marginRight: theme.spacing.unit * .5,
    width: "48%"
  }
});


class Account extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      snackOpen: false,
      snackVariant:"success",
      snackMessage:"Password has been updated",
      colorPickerPrimary: true,
      colorPickerSecondary: true,
      colorPickerText: true,
      allowCancelations: false,
      logo:"",
      business_name:"AdvancEarly",
      primary_color:"",
      secondary_color: "",
      text_color: "#fff",
      url:"https://www.integrateyourbusiness.com",
      request_cancelation: false
    };
  }

   changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`
    this.props.history.push(path);
    };

    handleChange = name => event => {
      this.setState({
        [name]: event.target.value,
      });
    };

    handleCheckboxChange = name => event => {
      this.setState({
        [name]: !this.state[name],
      });
    };


    showCancelation= () => {
      this.setState({
        request_cancelation: !this.state.request_cancelation
    })
  };

    handleSnackClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      this.setState({ snackOpen: false });
    };







componentDidMount(event){
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    if(response.status === 200){
      self.setState({
      org: response.data,
      finished: true
      });

    window.intercomSettings = {
        app_id: "nhry3flh",
        user_id: response.data.admins[0]._id,
        name: `${response.data.admins[0].first_name} ${response.data.admins[0].last_name}`, // Full name
        email: response.data.admins[0].username, // Email address
        created_at: response.data.createdAt,
        org_handle: response.data.org_handle,
        connected_stripe: response.data.stripe_credentials ? true : false,
        user_hash: localStorage.getItem('intercom_hash')
        //created_at: <%= current_user.created_at.to_i %> // Signup Date
      };

    }else{
      let path = `/${self.state.org_handle}/login`
      self.props.history.push(path);
    }
  })
  .catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving user profile"})
  });
}

render() {
  console.log(this.state)
  const { classes, ...other } = this.props;

    return (
      <NavBar2 header="Account Settings" {...other}>
      <Grid container spacing={24}>

      <Paper className={classes.paper} elevation={4}>
      <AppBar position="static" className={classes.paperAppBar} color="secondary">
         <Toolbar>
           <Typography variant="title" color="inherit" className={classes.flex}>
             Current Plan
           </Typography>
         </Toolbar>
       </AppBar>
       {
         this.state.org && this.state.org.billing && this.state.org.billing.subscriptions.total_count != 0 ?
       <table>

         <tr>
          <td>Current Plan: InstaPayments Monthly</td>
        </tr>
        <tr>
          <td>Next Amount: ${this.state.org.billing.subscriptions.data[0].plan.amount/100}</td>
        </tr>
        <tr>
          <td>Next Billing: {new Date(this.state.org.billing.subscriptions.data[0].current_period_end*1000).toString()}</td>
        </tr>
        <tr>
          <td>Status: {this.state.org.billing.subscriptions.data[0].status}</td>
        </tr>
        <tr>
          <td>Current Card: {this.state.org.billing.sources.total_count !=0 ?
            this.state.org.billing.sources.data[0].brand + " " +
            this.state.org.billing.sources.data[0].last4

            : "no card on file"}</td>
         </tr>
       </table>
       :
       "no current billing on your account."
     }

      </Paper>

      <Paper className={classes.paper} elevation={4}>
      <AppBar position="static" className={classes.paperAppBar} color="secondary">
         <Toolbar>
           <Typography variant="title" color="inherit" className={classes.flex}>
             Update your Card
           </Typography>
         </Toolbar>
       </AppBar>
       <StripeProvider apiKey="pk_live_MFpg2VvUxONHCsbIqLKrrnEh">
          <CardUpdate { ...this.props } />
       </StripeProvider>
      </Paper>

      <Paper className={classes.paper} elevation={4}>
      <AppBar position="static" className={classes.paperAppBar} color="secondary">
         <Toolbar>
           <Typography variant="title" color="inherit" className={classes.flex}>
             Other
           </Typography>
         </Toolbar>
       </AppBar>
      <form className={classes.container} noValidate autoComplete="off">
      <Typography>

      </Typography>
        <Grid container justify={"center"}>
        <br />
        <Button
        variant="outlined"
        color="secondary"

        className={classes.button}
        onClick={(event) => this.showCancelation()}
        >
        Request Cancelation
        </Button>
        </Grid>
        <br/>
        { this.state.request_cancelation ?
        <Grid container justify={"center"}>
        <Typography>
        Please email <a href="mailto:support@instapayments.io">support@instapayments.io</a> from the email you signed up with to begin the cancelation process.
        </Typography>
        </Grid> : null
        }
        </form>
      </Paper>
      </Grid>

        <Snackbar
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'center',
           }}
           open={this.state.snackOpen}
           autoHideDuration={3000}
           onClose={this.handleSnackClose}
           >
             <MySnackbarContentWrapper
             variant={this.state.snackVariant}
             message={this.state.snackMessage}
             onClose={this.handleSnackClose}
            />
        </Snackbar>
      </NavBar2>
    );
  }
}


//
/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Account);
