import React from 'react';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import getSymbolFromCurrency from 'currency-symbol-map';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import NavBar2 from '../../components/navigation/NavBar2';
import Directions from '../../components/directions/Directions';
import Table from '@material-ui/core/Table';
import Divider from '@material-ui/core/Divider';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
//import { styles } from './OrgPageStyles.js';
import moment from 'moment';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => ({
   loader:{
     marginTop: "15%"
   },
  orgCard:{
     width: "100%",
     marginBottom: "2%"
   }
});


class Org extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 10,
      orgs:[],
      firstLoadAttempted: false
    };
  }

  changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`
    this.props.history.push(path);
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snackOpen: false });
  };

componentDidMount(){
  console.log(this.state)
  this.fetchOrg();
  window.intercomSettings = {
      app_id: "nhry3flh",
      user_hash: localStorage.getItem('intercom_hash')
      //created_at: <%= current_user.created_at.to_i %> // Signup Date
    };
}

fetchForms = ()=>{
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/forms/org/${self.props.match.params.orgId}`,
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      self.setState({
      forms: response.data,
      finished: true,
      firstLoadAttempted: true
      });
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({
      messageError:"Error retrieving orgs",
      firstLoadAttempted: true
    })
  });
}

verifyConnection = ()=>{
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/orgs/stripe/${self.props.match.params.orgId}/verify`,
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    console.log(response.data);

    if(response.status === 200){
      self.setState({
        snackOpen: true,
        snackVariant:"success",
        snackMessage:"The Stripe connection was valid."
      });

    }})
  .catch(function (error) {
    self.setState({
      snackOpen: true,
      snackVariant:"error",
      snackMessage:"The Stripe connection returned an error."
    });

  });
}


fetchProducts = ()=>{
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/products/org/${self.props.match.params.orgId}`,
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      self.setState({
      products: response.data,
      finished: true,
      firstLoadAttempted: true
      });
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({
      messageError:"Error retrieving orgs",
      firstLoadAttempted: true
    })
  });
}

fetchOrg = ()=>{
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/orgs/${self.props.match.params.orgId}`,
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      self.setState({
      org: response.data,
      finished: true,
      firstLoadAttempted: true
      });
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({
      messageError:"Error retrieving orgs",
      firstLoadAttempted: true
    })
  });
}

render() {
  const { classes, ...other } = this.props;
  const products = [];
  const forms = [];
  const admins = [];

if (this.state.products) {
  for (let product of this.state.products) {
    let productUrl = `https://dashboard.stripe.com/${this.state.org && this.state.org.stripe_credentials && this.state.org.stripe_credentials.stripe_user_id}/subscriptions/products/${product.id}`
    products.push(
      <TableRow key={product.id}>
        <TableCell >{product.name || product.id}</TableCell>
        <TableCell >{product.type}</TableCell>
        <TableCell>{product.plans && product.plans.length}</TableCell>
        <TableCell >
        {moment(new Date(product.created*1000)).format('MM/DD/YYYY')}
        </TableCell>
        <TableCell >
        <Button size="small" variant="outlined" color="primary" target="_blank" href={productUrl} >
        View
        </Button>
        </TableCell>
      </TableRow>
    )

  }
}

if (this.state.forms) {
  for (let form of this.state.forms) {

    let formUrl = `https://forms.instapayments.io/${form.type}/${form._id}`;

    forms.push(
      <TableRow key={form.id}>
        <TableCell >{form.name || form.id}</TableCell>
        <TableCell >{form.type}</TableCell>
        <TableCell>{form.line_items && form.line_items.length}</TableCell>
        <TableCell >
        {moment(new Date(form.createdAt)).format('MM/DD/YYYY')}
        </TableCell>
        <TableCell >
        <Button size="small" variant="outlined" color="primary" target="_blank" href={formUrl} >
        View
        </Button>
        </TableCell>
      </TableRow>
    )

  }
}
console.log("STATE", this.state)
if (this.state.org) {
  for (let admin of this.state.org.admins) {

    admins.push(
      <TableRow key={admin._id}>
        <TableCell >{admin.first_name + " " + admin.last_name}</TableCell>
        <TableCell >{admin.email}</TableCell>
        <TableCell>{admin.phone}</TableCell>
      </TableRow>
    )

  }
}



    return (
      <NavBar2 header="Orgs" {...this.props}>


  {this.state.firstLoadAttempted ?
    <div>
        { this.state.org ?
          <div>
          <Grid container justify={"center"}>
            <Card className={classes.orgCard}>
            <CardContent>
            <Typography gutterBottom variant="headline" component="h4">
            Details
            </Typography>

            </CardContent>
            <Divider/>
                  <CardContent>
                    <table>
                    <tbody>
                      <tr>
                        <td>Org Id</td>
                        <td>{this.state.org._id}</td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>{this.state.org.organization_name}</td>
                      </tr>
                      <tr>
                        <td> Created </td>
                        <td>{moment(new Date(this.state.org.createdAt)).format('MM/DD/YYYY')}</td>
                      </tr>
                      <tr>
                        <td>Org Handle</td>
                        <td>{this.state.org.org_handle || "none"} </td>
                      </tr>
                      <tr>
                        <td>Active Customers</td>
                        <td>{this.state.org.active_customers || "none"} </td>
                      </tr>
                      <tr>
                        <td>Total Customers</td>
                        <td>{this.state.org.total_customers || "none"} </td>
                      </tr>
                      <tr>
                        <td>Login Url</td>
                        <td>
                        <a target="_blank" href={`https://orgs.instapayments.io/${this.state.org.org_handle}/login`}>
                        {`https://orgs.instapayments.io/${this.state.org.org_handle}/login`}
                        </a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </CardContent>
            </Card>

            <Card className={classes.orgCard}>
            <CardContent>
            <Typography gutterBottom variant="headline" component="h4">
            Billing
            </Typography>

            </CardContent>
            <Divider/>
                  <CardContent>
                    <table>
                    <tbody>
                      <tr>
                        <td>Billing ID</td>
                        <td>{this.state.org.billing && this.state.org.billing.id}</td>
                      </tr>
                      <tr>
                        <td>Active Subscriptions</td>
                        <td>{this.state.org.billing.subscriptions.total_count}</td>
                      </tr>
                      <tr>
                        <td>Plan</td>
                        <td>
                        {this.state.org.billing.subscriptions.total_count > 0 ?
                          this.state.org.billing.subscriptions.data[0].items.data[0].plan.nickname
                          : null
                        }
                          </td>
                      </tr>
                      </tbody>
                    </table>
                  </CardContent>
              <CardActions>
                <Button
                variant="raised"
                color="primary"
                href={`https://dashboard.stripe.com/customers/${this.state.org.billing.id}`}
                target="_blank"
                >
                Mange in Stripe
                </Button>
              </CardActions>
            </Card>

            <Card className={classes.orgCard}>
            <CardContent>
            <Typography gutterBottom variant="headline" component="h4">
            Stripe Connection
            </Typography>

            </CardContent>
            <Divider/>
                  <CardContent>
                    <table>
                    <tbody>
                      <tr>
                        <td>Stripe Account ID</td>
                        <td>{this.state.org.stripe_credentials && this.state.org.stripe_credentials.stripe_user_id}</td>
                      </tr>
                      <tr>
                        <td>Publishable Key</td>
                        <td>{this.state.org.stripe_credentials && this.state.org.stripe_credentials.stripe_publishable_key}</td>
                      </tr>
                      <tr>
                        <td>Scope</td>
                        <td>
                        {this.state.org.stripe_credentials && this.state.org.stripe_credentials.scope}
                          </td>
                      </tr>
                      </tbody>
                    </table>
                  </CardContent>
              <CardActions>
                <Button
                variant="raised"
                color="primary"
                onClick={this.verifyConnection}
                >
                Verify Connection
                </Button>
              </CardActions>
            </Card>
            <Card className={classes.orgCard}>
            <CardContent>
            <Typography gutterBottom variant="headline" component="h4">
            Admins
            </Typography>
            </CardContent>

            <Divider/>
                  <CardContent>
                  <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Created</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {admins}
                  </TableBody>
                  </Table>
                  </CardContent>
            </Card>



            <Card className={classes.orgCard}>
            <CardContent>
            <Typography gutterBottom variant="headline" component="h4">
            Products
            </Typography>
            <Button
            variant="raised"
            color="primary"
            onClick={this.fetchProducts}
            >
            Load Products
            </Button>
            </CardContent>
            <Divider/>
            { this.state.products ?
                  <CardContent>
                  <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Plans</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>View</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {products}
                  </TableBody>
                  </Table>
                  </CardContent>
                  : null}

            </Card>


            <Card className={classes.orgCard}>
            <CardContent>
            <Typography gutterBottom variant="headline" component="h4">
            Forms
            </Typography>
            <Button
            variant="raised"
            color="primary"
            onClick={this.fetchForms}
            >
            Load Forms
            </Button>
            </CardContent>
            <Divider/>
            { this.state.forms ?
              <CardContent>
              <Table>
              <TableHead>
              <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Plans</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>View</TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {forms}
              </TableBody>
              </Table>
              </CardContent>
              : null}

              </Card>
          </Grid>
          </div>
        :
        <div>
        <Grid container justify={"center"}>
        <Card className={classes.card}>
   <CardActionArea>
     <CardMedia
       className={classes.media}
       image="/static/images/cards/contemplative-reptile.jpg"
       title="Contemplative Reptile"
     />
     <CardContent>
       <Typography gutterBottom variant="headline" component="h2">
         You don't have a org yet!
       </Typography>
       <Typography component="p">
         Orgs provide an easy way for your customers to signup with Stripe.
       </Typography>
     </CardContent>
   </CardActionArea>
   <CardActions>
     <Button
     variant="raised"
     color="primary"
     href="/orgs/new/v2"
     >
       Create a Org
     </Button>
   </CardActions>
 </Card>
 </Grid>
 </div>
      }
      </div>
        :
        <Grid container justify={"center"}>
        <div className={classes.loader}>
        <CircularProgress size={100} className={classes.progress} />
        </div>
        </Grid>
      }
      <Snackbar
         anchorOrigin={{
           vertical: 'bottom',
           horizontal: 'center',
         }}
         open={this.state.snackOpen}
         autoHideDuration={3000}
         onClose={this.handleSnackClose}
         >
           <MySnackbarContentWrapper
           variant={this.state.snackVariant}
           message={this.state.snackMessage}
           onClose={this.handleSnackClose}
          />
      </Snackbar>
        </NavBar2>
    );
  }

}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Org);
