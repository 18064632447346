import Paper from '@material-ui/core/Paper';
import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import NavBar2 from '../../components/navigation/NavBar2';
import axios from 'axios';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = theme => ({
  paper:{
    minHeight: "90%",
    minWidth: "90%"
  },
  tableRow:{
    maxWidth:"10%"
  }
});


class Dashboard extends React.Component {
  constructor(props){
    super(props);
    this.state={
      tweets: [],
      page: 1,
      rowsPerPage: 10,
    }
  }

fetchTweets = (page, per_page) => {
  var self = this;
axios({
 method: 'get',
 url: `${apiBaseUrl}/v1/tweets?page=${page}&per_page=${per_page}`,
 headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
})
.then(function (response) {
  console.log(response.data);
  if(response.status === 200){
    self.setState({
    tweets: response.data,
    finished: true
    });
  }})
.catch(function (error) {
  console.log(error);
  self.setState({messageError:"Error retrieving forms"})
});
}

handleChange = name => event => {
   this.setState({
     [name]: event.target.value,
   });
 };

  handleClick(event){
    let path = `/dashboard`
    this.props.history.push(path);
}

componentDidMount(){
  this.fetchTweets();
}

  render() {
    const { classes } = this.props;

    const formattedTweets = [];

    return (
    <div className={classes.root}>
    <NavBar2>
    <Grid container className={classes.root} spacing={24} justify={"center"}>

    {formattedTweets}

    </Grid>
    </NavBar2>
    </div>
  );
}
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Dashboard);
