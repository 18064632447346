import Paper from '@material-ui/core/Paper';
import React from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => ({
  root:{
    marginTop:"10%"
  },
  background:{
    //backgroundColor: "grey"
  },
  paper: theme.mixins.gutters({
    paddingTop: 50,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
  }),
  container: {
   display: 'flex',
   flexWrap: 'wrap',
 },
 textField: {
   marginLeft: theme.spacing.unit,
   marginRight: theme.spacing.unit,
 },
 button: {
   //width:00,
    margin: theme.spacing.unit,
  },
  bigAvatar: {
  width: 75,
  height: 75,
   backgroundColor: "#fff"
  },
 menu: {
   width: 100,
 },
 logo:{
 maxWidth: "60px",
 maxHeight: "60px",
}
});


class Login extends React.Component {
  constructor(props){
    super(props);
    this.state={
      username:'test',
      password:'',
      messageError:'',
      usernameMessage:'Enter your username',
      passwordMessage:'Enter your password',
      usernameInvalid: false,
      passwordInvalid: false
    }
  }
  componentDidMount(){
  /*  window.Intercom("boot", {
      app_id: "nhry3flh"
    });*/
  }



  handleChange = name => event => {
   this.setState({
     [name]: event.target.value,
   });
 };

  handleClick(event){
    event.preventDefault();
    console.log(this.state)
    var self = this;

    var payload={
      "username":this.state.username,
      "password":this.state.password,
    }
    let url = "";
    if (self.props.match.params.org) {
      url = `${apiBaseUrl}/v1/accounts/${self.props.match.params.org}/login`;
    }else{
      url=`${apiBaseUrl}/v1/accounts/login`
    }
    axios.post(url, payload)
    .then(function (response) {
      console.log(response);
      if(response.status === 200){
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('org_handle', self.props.match.params.org);
          localStorage.setItem('timestamp', new Date().getTime());
          localStorage.setItem('name', `${response.data.first_name} ${response.data.last_name}`);
          localStorage.setItem('first_name', response.data.first_name);
          localStorage.setItem('last_name', response.data.last_name);
          localStorage.setItem('gravatar', `https://www.gravatar.com/avatar/${response.data.gravatar}?d=mp`);
          localStorage.setItem('org', response.data.org);
          localStorage.setItem('org_id', response.data.org_id);
          localStorage.setItem('orgId', response.data.org_id);
          localStorage.setItem('admin_id', response.data.admin_id);
          localStorage.setItem('intercom_hash', response.data.intercom_hash);

          let path = `/orgs`
          self.props.history.push(path);
      }})
    .catch(function (error) {
      console.log(error);
      self.setState(
        {
          usernameMessage:"Incorrect Username or Password",
          passwordMessage:"Incorrect Username or Password",
          usernameInvalid: true,
          passwordInvalid: true
      })
    });
}

  render() {
    const { classes } = this.props;
    return (
    <div className={classes.background}>
    <Grid container className={classes.root} justify={"center"}>

      <Paper className={classes.paper} elevation={4}>
      <Grid container justify={"center"}>
      <Avatar aria-label="Logo" className={classes.bigAvatar}>
            <img className={classes.logo} src="/images/icon2.png" alt="logo" />
      </Avatar>
      </Grid>

      <form className={classes.container} onSubmit={(event) => this.handleClick(event)}>
        <TextField
        label="Email"
        fullWidth
        required
        error={this.state.usernameInvalid}
        className={classes.textField}
        helperText={this.state.usernameMessage}
        onChange={this.handleChange('username')}
        />

        <TextField
        label="Password"
        fullWidth
        required
        error={this.state.passwordInvalid}
        type="password"
        className={classes.textField}
        helperText={this.state.passwordMessage}
        onChange={this.handleChange('password')}
        />

        <Grid container justify={"center"}>
        <br />
        <Button
        variant="raised"
        color="primary"
        fullWidth={true}
        className={classes.button}
        type="submit"
        >
        Login
        </Button>
        </Grid>

        </form>
        <Typography type="caption">
        <a href={`/${this.props.match.params.org}/password/reset`}>reset password</a>
        </Typography>
      </Paper>
    </Grid>
    </div>
  );
}
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Login);
